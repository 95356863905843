<template>
  <div>
    <div class="page-header d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center">
        <router-link :to="{ name: 'resource_center' }">
          <button class="btn btn-control">
            <img
              alt="Return back"
              src="@/assets/icons/bold-icon previous.svg"
            />
          </button>
        </router-link>
        <h3 class="page-header-title ms-2">Ad Wizard</h3>
      </div>
    </div>
    <div class="page-content p-0">
      <div class="row m-auto">
        <div class="col-md-7 p-0 border-right-light border-bottom-light">
          <div class="row p-3 w-100 mx-auto">
            <div class="col-10 my-auto">
              <div class="col-title">Ad Preview</div>
            </div>
            <div class="col-2 d-flex flex-row-reverse">
              <button
                :disabled="ui.isDownloading"
                class="btn btn-control"
                @click="download"
              >
                <span v-if="ui.isDownloading">...</span>
                <img
                  v-else
                  alt="Download Wizard"
                  src="@/assets/icons/icon-download.svg"
                />
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-5 d-sm-none d-lg-block p-0 border-bottom-light">
          <div class="row p-3 w-100 mx-auto h-100">
            <div class="col-9 my-auto">
              <div class="col-title">Editor</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row m-auto">
        <div class="col-lg-7 p-0 border-right-light">
          <div class="col-12 preview-wizard">
            <!--  Page Content  -->
            <div class="page-content p-normal d-flex justify-content-center">
              <div v-if="ui.loading" class="text-center mt-5">
                <div class="spinner-border mt-5" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
              <pdf v-else :src="src" :page="1" class="page-preview" />
            </div>
            <!--  Full Page preview  -->
            <!-- <transition mode="out-in" name="component-fade">
              <full-page-preview v-if="editor.wizardType === 'Full Page'"
                                 :previewData="editor"/>
            </transition>

            <transition mode="out-in" name="component-fade">
              <half-page-preview v-if="editor.wizardType === 'Half Page'"
                                 :previewData="editor"/>
            </transition>

            <transition mode="out-in" name="component-fade">
              <quarter-page-preview v-if="editor.wizardType === 'Quarter Page'"
                                    :previewData="editor"/>
            </transition> -->
          </div>
        </div>
        <div class="col-lg-5 pt-3 ps-0 pe-0">
          <div class="col-12 pb-4 border-bottom-light">
            <div class="ps-4 pe-4">
              <div class="form-group full-width">
                <label for="wizardType">
                  Ad size: <span>{{ editor.wizardType }}</span>
                </label>
                <div id="wizardType" class="d-flex mt-3">
                  <button
                    :class="{ active: editor.wizardType === 'Full Page' }"
                    class="btn-wizard-type"
                    @click="changeWizardType('Full Page')"
                  >
                    <div class="wizard-size full"></div>
                    <span class="wizard-type-title"> Full Page 8.5″x 11″ </span>
                  </button>
                  <button
                    :class="{ active: editor.wizardType === 'Half Page' }"
                    class="btn-wizard-type ms-3 d-flex align-items-end"
                    @click="changeWizardType('Half Page')"
                  >
                    <div class="wizard-size half"></div>
                    <span class="wizard-type-title"> Half Page 7.875″x5″ </span>
                  </button>
                  <button
                    :class="{ active: editor.wizardType === 'Quarter Page' }"
                    class="btn-wizard-type ms-3 d-flex align-items-end"
                    @click="changeWizardType('Quarter Page')"
                  >
                    <div class="wizard-size quarter"></div>
                    <span class="wizard-type-title"> Quarter 3.875″x5″ </span>
                  </button>
                </div>
              </div>
              <div class="form-group full-width mt-3">
                <label for="fieldHeader">Header</label>
                <textarea
                  id="fieldHeader"
                  v-model="editor.header"
                  class="form-control"
                  cols="30"
                  rows="3"
                  @change="updatePreview"
                >
                </textarea>
              </div>
              <div class="form-group full-width mt-3">
                <label for="fieldDescription"> Description </label>
                <textarea
                  id="fieldDescription"
                  v-model="editor.description"
                  class="form-control"
                  cols="30"
                  rows="5"
                  @change="updatePreview"
                ></textarea>
              </div>
              <div
                v-if="editor.wizardType === 'Full Page'"
                class="form-group mt-3"
              >
                <label for="bulletsField"> Bullets </label>
                <textarea
                  id="bulletsField"
                  v-model="editor.bullets"
                  class="form-control"
                  cols="30"
                  rows="4"
                  @change="updatePreview"
                ></textarea>
              </div>
              <div class="form-group mt-3">
                <label for="aprField">APR</label>
                <div class="input-with-icon mb-2 w-75">
                  <span class="icon">%</span>
                  <input
                    id="aprField"
                    v-model="editor.apr"
                    class="form-control"
                    step="0.01"
                    type="number"
                    @change="updatePreview"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 ps-4 pe-4 pb-4 border-bottom-light">
            <div>
              <div class="form-group full-width mt-3">
                <div class="col-12">
                  <label for="contactField">Your Contact</label>
                </div>
                <div id="contactField" class="row mt-3">
                  <div
                    class="col-md-2"
                    @click="showUploadPhoto = !showUploadPhoto"
                  >
                    <v-avatar
                      v-if="editor.customPhoto === false"
                      :size="84"
                      :src="agentPhoto(editor.contact.photo)"
                      rounded
                    />
                    <v-avatar
                      v-else
                      :size="84"
                      :src="editor.contact.photo"
                      rounded
                    />
                  </div>
                  <div class="col-sm-6 col-md-5 my-md-auto mt-3">
                    <div class="form-group">
                      <multiselect
                        id="fieldAgent"
                        v-model="editor.contact"
                        :allow-empty="false"
                        :close-on-select="true"
                        :options="loans"
                        :searchable="false"
                        :show-labels="false"
                        label="fullName"
                        placeholder="Agent Full Name"
                        @input="selectAgent"
                      >
                      </multiselect>
                    </div>
                    <div class="form-group mt-3">
                      <input
                        v-model="editor.contact.nmls"
                        aria-label="NMLS"
                        class="form-control"
                        placeholder="NMLS (Optional)"
                        type="number"
                        @change="updatePreview"
                      />
                    </div>
                  </div>
                  <div class="col-sm-6 col-md-5 my-auto">
                    <div class="form-group">
                      <input
                        v-model="editor.contact.position"
                        aria-label="Position"
                        class="form-control"
                        placeholder="Position"
                        type="text"
                        @change="updatePreview"
                      />
                    </div>
                    <div class="form-group mt-3">
                      <input
                        v-model="editor.contact.phone"
                        aria-label="Phone"
                        class="form-control"
                        v-mask="'(###) ###-####'"
                        placeholder="Phone"
                        type="text"
                        @change="updatePreview"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="editor.showPartnerContact === false"
            class="
              col-12
              ps-4
              pe-4
              pt-2
              pb-2
              cursor-pointer
              border-bottom-light
            "
            @click="editor.showPartnerContact = true"
          >
            <div class="d-flex align-items-center add-btn">
              <img
                alt=""
                src="@/assets/icons/Checkbox-Hover-icon-add blue.svg"
              />
              <span>Add Partner Contact</span>
            </div>
          </div>
          <div
            v-else
            class="col-12 ps-4 pe-4 pb-4 pt-3 border-bottom-light add-contact"
          >
            <div class="row">
              <div class="col-10 my-auto">
                <span class="add-contact--title">Your Contact</span>
              </div>
              <div class="col-2 d-flex flex-row-reverse">
                <button
                  class="btn btn-control cursor-pointer"
                  @click="deletePartner"
                >
                  <img alt="" src="@/assets/icons/icon-delete.svg" />
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2">
                <div class="d-flex upload-image" @click="toggleShow">
                  <v-avatar
                    v-if="editor.customPartnerPhoto === false"
                    :size="84"
                    :src="agentPhoto(editor.addContact.info.photo)"
                    rounded
                  />
                  <v-avatar
                    v-else
                    :size="84"
                    :src="editor.addContact.imgDataUrl"
                    rounded
                  />
                  <span
                    v-if="
                      editor.customPartnerPhoto === false &&
                      Object.keys(this.editor.addContact.info).length === 0
                    "
                    class="position-absolute"
                    >Add Photo</span
                  >
                </div>
              </div>
              <div class="col-sm-6 col-md-5 my-md-auto mt-3">
                <div class="form-group">
                  <multiselect
                    id="fieldPartner"
                    v-model="editor.addContact.info"
                    :allow-empty="false"
                    :close-on-select="true"
                    :options="loans"
                    :searchable="false"
                    :show-labels="false"
                    label="fullName"
                    placeholder="Select Partner"
                    @input="selectPartner"
                  >
                  </multiselect>
                </div>
                <div class="form-group mt-3">
                  <input
                    v-model="editor.addContact.info.nmls"
                    aria-label="NMLS"
                    class="form-control"
                    placeholder="NMLS (Optional)"
                    type="number"
                    @change="updatePreview"
                  />
                </div>
              </div>
              <div class="col-sm-6 col-md-5 my-auto">
                <div class="form-group">
                  <input
                    v-model="editor.addContact.info.position"
                    aria-label="Position"
                    class="form-control"
                    placeholder="Position"
                    type="text"
                    @change="updatePreview"
                  />
                </div>
                <div class="form-group mt-3">
                  <input
                    v-model="editor.addContact.info.phone"
                    aria-label="Phone"
                    class="form-control"
                    placeholder="Phone"
                    v-mask="mask"
                    type="text"
                    @change="updatePreview"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 ps-4 pe-4 pb-4 pt-3 border-bottom-light">
            <div class="form-group full-width mt-3">
              <label for="fieldDisclamer">Disclaimer</label>
              <textarea
                id="fieldDisclamer"
                v-model="editor.disclamer"
                class="form-control"
                cols="30"
                rows="5"
                @change="updatePreview"
              >
              </textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
    <transition mode="out-in" name="component-fade">
      <my-upload
        v-model="show"
        :height="300"
        :noSquare="true"
        :params="params"
        :width="300"
        :url="
          backendUrl(
            `/api/v1/wizard/upload/${
              this.editor.addContact.info ? this.editor.addContact.info.id : 0
            }`
          )
        "
        field="img"
        img-format="png"
        lang-type="en"
        @crop-success="cropPhotoSuccessPartner"
        @crop-upload-success="cropUploadSuccessPartner"
        @crop-upload-fail="cropUploadFailPartner"
      />
    </transition>

    <transition mode="out-in" name="component-fade">
      <my-upload
        v-model="showUploadPhoto"
        :height="300"
        :noSquare="true"
        :params="params"
        :width="300"
        :url="backendUrl(`/api/v1/wizard/upload/${this.editor.contact.id}`)"
        field="img"
        img-format="png"
        lang-type="en"
        @crop-success="cropPhotoSuccess"
        @crop-upload-success="cropUploadSuccess"
        @crop-upload-fail="cropUploadFail"
      />
    </transition>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
// import html2canvas from 'html2canvas';
// import FullPagePreview from "./preview/FullPagePreview";
// import HalfPagePreview from "./preview/HalfPagePreview";
// import QuarterPagePreview from "./preview/QuarterPagePreview";
import pdf from "vue-pdf";
import { getBackendUrl } from "@/utils/backendUrl";

export default {
  name: "AdWizard",
  components: {
    // QuarterPagePreview,
    // HalfPagePreview,
    // FullPagePreview,
    Multiselect,
    pdf,
  },
  data() {
    return {
      ui: {
        isDownloading: false,
        loading: false,
      },
      editor: {
        wizardType: "Full Page",
        description:
          "We understand financing your home is a big deal and we’re here to help you get it right. " +
          "Our professional mortgage experts simplify your home-financing process " +
          "and ensures that you get all the best from us.",
        apr: 2.75,
        bullets: "",
        contact: {},
        showPartnerContact: false,
        header: "It’s a great time to purchase " + "or refinance your home.",
        disclamer:
          "Disclosed rate assumes a $400,000 rate and term refinance loan on an owner-occupied single family residence in California." +
          " Minimum 740 FICO credit score, 50% loan to value with no subordinate debt. If these factors are not met, you may still qualify and " +
          "additional pricing adjustments may apply. Subject to underwriting approval. Not all applicants will qualify. Equal Housing Lender. " +
          "Loans made or arranged pursuant to a California Department of Real Estate License #02110465. NMLS 1935005 Clear Mortgage Capital, Inc.",
        addContact: {
          info: {},
          imgDataUrl: "",
        },
        customPhoto: false,
        customPartnerPhoto: false,
      },
      params: {
        token: "123456798",
        name: "avatar",
      },
      loans: [],
      show: false,
      showUploadPhoto: false,
      wizardSize: "8.5X11",
      mask: "(###) ###-####",
      src: null,
      baseUrl:
        getBackendUrl() +
        `/api/v1/wizard/download/${this.$store.getters.getUserId}`,
    };
  },
  methods: {
    getAgents() {
      this.$http
        .get(`api/v1/form/wizard-agents`)
        .then((res) => {
          this.loans = res.data;
          this.loans = this.loans.map((el) => {
            if (el.phone.length > 0) {
              el.phone = this.formatUSNumber(el.phone);
            }
            return el;
          });
        })
        .catch();
    },
    download() {
      this.ui.isDownloading = true;
      this.$http
        .get(`/api/v1/wizard/download/${this.$store.getters.getUserId}`, {
          params: this.prepareFormData(true),
          responseType: "arraybuffer",
        })
        .then((res) => {
          this.generateFile(res);
        })
        .catch(() => {
          this.pushAlert("error", "Failed Generate");
        })
        .finally(() => (this.ui.isDownloading = false));
    },
    generateFile(res) {
      let fileURL = window.URL.createObjectURL(
          new Blob([res.data], { type: "application/pdf" })
        ),
        fileLink = document.createElement("a"),
        uname =
          this.editor.contact.fullName.split(" ")[0] +
          this.editor.contact.fullName.split(" ")[1];

      fileLink.href = fileURL;

      if (this.editor.showPartnerContact === true) {
        let partnerName =
          this.editor.addContact.info.fullName.split(" ")[0] +
          this.editor.addContact.info.fullName.split(" ")[1];
        fileLink.download = `adwizard_${uname}_and_${partnerName}_${this.wizardSize}.pdf`;
      } else {
        fileLink.download = `adwizard_${uname}_${this.wizardSize}.pdf`;
      }

      document.body.appendChild(fileLink);
      fileLink.click();
      fileLink.remove();
      this.ui.isDownloading = false;
    },
    // generateImage() {
    //   this.ui.isDownloading = true
    //   html2canvas(document.querySelector("#preview"), {allowTaint: true, useCORS: true, scale: 5, dpi: 300}).then(canvas => {
    //     let link = document.createElement('a'),
    //       uname = this.editor.contact.fullName.split(' ')[0] + this.editor.contact.fullName.split(' ')[1];

    //     if (this.editor.showPartnerContact === true) {
    //       let partnerName = this.editor.addContact.info.fullName.split(' ')[0] + this.editor.addContact.info.fullName.split(' ')[1];
    //       link.download = `adwizard_${uname}_and_${partnerName}_${this.wizardSize}.png`;
    //     } else {
    //       link.download = `adwizard_${uname}_${this.wizardSize}.png`;
    //     }
    //     link.href = canvas.toDataURL("image/png", 1);
    //     link.click();
    //     link.remove();
    //     this.ui.isDownloading = false
    //   });
    // },
    changeWizardType(val) {
      this.editor.wizardType = val;
      if (val === "Full Page") {
        this.wizardSize = "8.5X11";
      } else if (val === "Half Page") {
        this.wizardSize = "7.875X5";
      } else {
        this.wizardSize = "3.875X5";
      }
      this.updatePreview();
    },
    deletePartner() {
      this.editor.addContact.info = {};
      this.editor.customPartnerPhoto = false;
      this.editor.showPartnerContact = false;
      this.updatePreview();
    },
    toggleShow() {
      this.show = !this.show;
    },
    cropSuccess(imgDataUrl) {
      this.editor.addContact.imgDataUrl = imgDataUrl;
    },
    cropPhotoSuccess(url) {
      this.editor.customPhoto = true;
      this.editor.contact.photo = url;
    },
    cropUploadSuccessPartner(imgDataUrl) {
      this.editor.addContact.imgDataUrl = imgDataUrl;
      this.updatePreview();
    },
    cropPhotoSuccessPartner(url) {
      this.editor.customPartnerPhoto = true;
      this.editor.addContact.imgDataUrl = url;
    },
    cropUploadSuccess() {
      this.show = false;
      this.updatePreview();
    },
    cropUploadFail() {
      this.show = false;
    },
    selectAgent() {
      this.editor.customPhoto = false;
      this.updatePreview();
    },
    selectPartner() {
      this.editor.customPartnerPhoto = false;
      this.updatePreview();
    },
    cropUploadFailPartner() {
      this.editor.customPartnerPhoto = true;
    },
    prepareFormData(isDownload, isMount = false) {
      if (isMount) {
        this.editor.contact = this.$store.state.user;
        this.editor.contact.fullName =
          this.editor.contact.firstName + " " + this.editor.contact.lastName;
      }

      let formData = {
        isDownload: isDownload ? 1 : 0,
        header: this.editor.header,
        wizardType: this.editor.wizardType.toLowerCase().replace(" ", "_"),
        description: this.editor.description,
        apr: this.editor.apr,
        bullets: this.editor.bullets,
        contact: this.editor.contact.id,
        contactName: this.editor.contact.fullName,
        contactNmls: this.editor.contact.nmls,
        contactPosition: this.editor.contact.position,
        contactPhone: this.editor.contact.phone,
        disclamer: this.editor.disclamer,
        partnerContact: 0,
        partnerContactName: "",
        partnerContactNmls: "",
        partnerContactPosition: "",
        partnerContactPhone: "",
        customPhoto: this.editor.customPhoto ? 1 : 0,
        customPartnerPhoto: this.editor.customPartnerPhoto ? 1 : 0,
      };

      if (Object.keys(this.editor.addContact.info).length) {
        formData.partnerContact = this.editor.addContact.info.id;
        formData.partnerContactName = this.editor.addContact.info.fullName;
        formData.partnerContactNmls = this.editor.addContact.info.nmls;
        formData.partnerContactPosition = this.editor.addContact.info.position;
        formData.partnerContactPhone = this.editor.addContact.info.phone;
      }

      return formData;
    },
    updatePreview() {
      this.ui.loading = true;
      this.$http
        .get(`/api/v1/wizard/download/${this.$store.getters.getUserId}`, {
          params: this.prepareFormData(false),
          responseType: "arraybuffer",
        })
        .then((res) => {
          this.src = window.URL.createObjectURL(
            new Blob([res.data], { type: "application/pdf" })
          );
        })
        .finally(() => (this.ui.loading = false));
    },
  },
  mounted() {
    this.ui.loading = true;
    let formData = this.prepareFormData(false, true);
    this.src = pdf.createLoadingTask(
      this.baseUrl + "?" + this.convertObjectToQueryParams(formData)
    );
    this.src.promise.then(() => {
      this.ui.loading = false;
    });
  },
  beforeMount() {
    this.getAgents();
  },
};
</script>

<style lang="scss" scoped>
.col-title {
  color: #000000;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
}

.border-bottom-light {
  border-bottom: 1px solid #e7e8e8;
}

.btn-control {
  height: 36px;
  width: 36px;
  border-radius: 8px;
}

.form-group {
  label {
    color: rgba(0, 0, 0, 0.7);
    font-size: 12px;
    letter-spacing: 0;
    line-height: 12px;

    span {
      color: #000000;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 12px;
    }
  }

  textarea {
    color: #000000;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
  }
}

.btn-wizard-type {
  position: relative;
  height: 120px;
  max-width: 96px;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: #ffffff;

  &:focus {
    outline: none;
  }

  &.active {
    border: 2px solid #017aff;

    .wizard-size {
      background-color: rgba(0, 110, 241, 0.2);
    }

    .wizard-type-title {
      color: #006ef1;
      font-weight: 500;
    }
  }

  .wizard-size {
    &.full {
      width: 99%;
      height: 93%;
    }

    &.half {
      width: 99%;
      height: 45%;
      margin-bottom: 4px;
    }

    &.quarter {
      width: 50%;
      height: 45%;
      margin-bottom: 4px;
    }

    background-color: rgba(104, 104, 104, 0.2);
  }

  .wizard-type-title {
    position: absolute;
    top: 18px;
    display: inline-block;
    padding: 0 15px;
    margin: 0 auto;
    width: 100%;
    left: 0;
    color: rgba(0, 0, 0, 0.7);
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
    text-align: center;
  }
}

.input-with-icon {
  input {
    height: 30px;
    min-height: 30px;
    border: 1px solid #d4d4d4;
    border-radius: 4px;
    background-color: #ffffff;
  }
}

.form-group {
  input {
    &.form-control {
      height: 30px;
      min-height: 30px;
      border: 1px solid #d4d4d4;
      border-radius: 4px;
      background-color: #ffffff;
    }
  }
}

textarea {
  border-radius: 4px;
}

.add-btn {
  span {
    display: inline-block;
    color: rgba(0, 0, 0, 0.46);
    font-size: 13px;
    letter-spacing: 0;
    line-height: 13px;
    margin-left: 8px;
  }
}

.add-contact {
  &--title {
    color: rgba(0, 0, 0, 0.7);
    font-size: 12px;
    letter-spacing: 0;
    line-height: 20px;
  }

  .upload-image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 84px;
    width: 84px;
    border-radius: 60px;
    border: 1px dashed rgba(0, 0, 0, 0.2);
    transition: all 0.3s;
    position: relative;
    overflow: auto;

    img {
      height: 84px;
      width: 84px;
      border-radius: 60px;
      border: 1px dashed rgba(0, 0, 0, 0.2);
    }

    & > div {
      border-radius: 0 !important;
    }

    &:hover {
      border: 1px dashed #0078ff;

      span {
        color: #017aff;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 14px;
        text-align: center;
      }
    }

    span {
      color: rgba(0, 0, 0, 0.46);
      font-size: 12px;
      letter-spacing: 0;
      line-height: 14px;
      text-align: center;
    }
  }

  .btn-control {
    img {
      width: 24px;
      height: 24px;
    }
  }
}

.preview-wizard {
  min-height: 100vh;
  margin-bottom: 50px;
}

::v-deep .multiselect {
  min-height: 30px;
  max-height: 30px;
  min-width: 180px;
  width: 100%;
  @media screen and (max-width: 568px) {
    max-width: 50%;
    min-width: 100%;
  }

  &.multiselect--active {
    .multiselect__tags {
      transition: all 0.3s;
      border: 1px solid rgba(38, 111, 229, 0.5);
      box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
    }

    .multiselect__select {
      padding: 4px 20px 4px 8px;
    }
  }

  &__tags {
    min-height: 30px;
    max-height: 30px;
    padding: 6px 40px 0 8px;
    transition: all 0.3s;
    border-radius: 4px;

    &:hover {
      border: 1px solid rgba(38, 111, 229, 0.2);
      box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
    }
  }

  &__select {
    min-height: 30px;
    max-height: 30px;
    padding: 4px 8px 4px 20px;

    &:before {
      top: 0%;
      position: relative;
      right: 0px;
      color: #999;
      border: solid rgba(0, 0, 0, 0.46);
      margin-top: 4px;
      border-width: 0px 1px 1px 0;
      display: inline-block;
      padding: 3px;
      content: "";
      transform: rotate(44deg);
    }
  }

  &__single {
    color: #000000;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
    @media screen and (max-width: 568px) {
      font-size: 9px;
    }
  }

  &__option {
    padding: 10px 12px;
    min-height: 36px;
    transition: all 0.3s;
    line-height: 20px;
    font-size: 14px;
    color: #000;
    @media screen and (max-width: 568px) {
      font-size: 9px;
    }

    &--highlight {
      background: #f3f3f3;
      line-height: 20px;
      font-size: 14px;
      color: #000;
      @media screen and (max-width: 568px) {
        font-size: 9px;
      }
    }
  }
}

::v-deep {
  .vue-image-crop-upload {
    .vicp-wrap {
      border-radius: 8px;
      padding: 35px;

      .vicp-close {
        right: 15px;
        top: 15px;

        .vicp-icon4 {
          &:before,
          &:after {
            background: lightgrey;
            box-shadow: none;
          }
        }
      }

      .vicp-step1 {
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          max-height: 36px;
          height: 36px;
          border-radius: 8px;
          opacity: 0.46;
          color: #000000;
          font-size: 12px;
          letter-spacing: 0;
          line-height: 14px;
          transition: all 0.3s;

          &:hover {
            opacity: 1;
          }
        }
      }

      .vicp-step2 {
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          max-height: 36px;
          height: 36px;
          border-radius: 8px;
          opacity: 0.46;
          color: #000000;
          font-size: 12px;
          letter-spacing: 0;
          line-height: 14px;
          transition: all 0.3s;

          &:hover {
            opacity: 1;
          }

          &.vicp-operate-btn {
            padding: 0 20px;
            background-color: #28a745;
            color: #ffffff;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0;
            opacity: 1;
            line-height: 22px;
            border-radius: 8px;
            text-decoration: none;
            @media screen and (max-width: 568px) {
              width: 120px;
              font-size: 10px;
            }

            &:hover {
              background-color: #1d9b3c;
              color: #ffffff;
            }
          }
        }

        .vicp-crop-right {
          width: 50%;
        }

        .vicp-preview-item-circle {
          img {
            border: 1px dashed rgba(0, 0, 0, 0.2);
          }
        }
      }

      .vicp-range {
        display: flex;
        align-items: center;

        input {
          border: none;
        }
      }
    }

    .vicp-operate {
      right: 30px;
    }

    .vicp-upload {
      width: 95%;
      margin: 0 auto;
    }

    .vicp-drop-area {
      width: 95%;
      margin: 20px auto;
    }

    .vicp-step2
      .vicp-crop
      .vicp-crop-right
      .vicp-preview
      .vicp-preview-item.vicp-preview-item-circle {
      img {
        border: 1px dashed rgba(0, 0, 0, 0.2);
      }
    }
  }
}

.page-preview {
  display: inline-block;
  width: 100%;
  border: #0a0a0a 1px solid;
  margin-bottom: 30px;

  .annotationLayer {
    display: none;
  }
}
</style>
